<script setup>
defineProps({
  settings: {
    type: Object,
    default: () => ({}),
  },
});

const logo = useLogo();
</script>

<template>
  <div
    id="footer"
    ref="root"
    class="layout-footer container"
    :class="{ 'layout-footer--grid-half': $route.meta.footerGridHalf }"
  >
    <div class="layout-footer__section layout-footer__section--top">
      <div class="layout-footer__col-1">
        <NuxtLink class="layout-footer__link layout-footer__link--logo" to="/">
          <img class="layout-footer__logo" :src="logo" alt="Logo" />
        </NuxtLink>

        <div v-if="settings.common.copyright" class="hidden-desktop">
          <span class="layout-footer__font layout-footer__font--text">
            {{ $tp(settings.common.copyright) }}
          </span>
        </div>
      </div>

      <div
        v-for="(phone, index) in settings.common.phones"
        :key="`phone-${index}`"
        class="layout-footer__contacts"
      >
        <a :href="`tel:${phone.value}`" class="layout-footer__phone link">
          <span class="layout-footer__font layout-footer__font--phone">
            {{ $tp(phone.value) }}
          </span>
        </a>

        <span class="layout-footer__font layout-footer__font--text">
          {{ $tp(phone.text) }}
        </span>
      </div>
    </div>

    <div class="layout-footer__section layout-footer__section--bottom">
      <span
        v-if="settings.common.copyright"
        class="layout-footer__font layout-footer__font--text hidden-mobile"
      >
        {{ $tp(settings.common.copyright) }}
      </span>

      <div v-if="settings.socials.mir" class="layout-footer__socials">
        <span
          class="layout-footer__font layout-footer__font--socials hidden-mobile"
        >
          {{ $tp($t('layout.footer.socials.mir.desktop')) }}
        </span>
        <span
          class="layout-footer__font layout-footer__font--socials hidden-desktop"
        >
          {{ $tp($t('layout.footer.socials.mir.mobile')) }}
        </span>

        <div class="layout-footer__icons">
          <a
            v-if="settings.socials.mir.tg"
            :href="settings.socials.mir.tg"
            target="_blank"
            class="layout-footer__link layout-footer__link--social link"
          >
            <CIcon class="layout-footer__icon" name="socials/telegram" />
          </a>
          <a
            v-if="settings.socials.mir.vk"
            :href="settings.socials.mir.vk"
            target="_blank"
            class="layout-footer__link layout-footer__link--social link"
          >
            <CIcon class="layout-footer__icon" name="socials/vk" />
          </a>
          <a
            v-if="settings.socials.mir.ok"
            :href="settings.socials.mir.ok"
            target="_blank"
            class="layout-footer__link layout-footer__link--social link"
          >
            <CIcon class="layout-footer__icon" name="socials/ok" />
          </a>
        </div>
      </div>

      <div v-if="settings.socials.sbp" class="layout-footer__socials">
        <span
          class="layout-footer__font layout-footer__font--socials hidden-mobile"
        >
          {{ $tp($t('layout.footer.socials.sbp.desktop')) }}
        </span>
        <span
          class="layout-footer__font layout-footer__font--socials hidden-desktop"
        >
          {{ $tp($t('layout.footer.socials.sbp.mobile')) }}
        </span>

        <div class="layout-footer__icons">
          <a
            v-if="settings.socials.sbp.tg"
            :href="settings.socials.sbp.tg"
            target="_blank"
            class="layout-footer__link layout-footer__link--social link"
          >
            <CIcon class="layout-footer__icon" name="socials/telegram" />
          </a>
          <a
            v-if="settings.socials.sbp.vk"
            :href="settings.socials.sbp.vk"
            target="_blank"
            class="layout-footer__link layout-footer__link--social link"
          >
            <CIcon class="layout-footer__icon" name="socials/vk" />
          </a>
          <a
            v-if="settings.socials.sbp.ok"
            :href="settings.socials.sbp.ok"
            target="_blank"
            class="layout-footer__link layout-footer__link--social link"
          >
            <CIcon class="layout-footer__icon" name="socials/ok" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.layout-footer {
  $parent: &;

  &__font {
    &--phone {
      font-size: em(40);
      line-height: em(31, 40);

      @include media-breakpoint-down(sm) {
        font-size: em(22);
        line-height: em(17, 22);
      }
    }

    &--text {
      font-size: em(20);
      font-weight: 250;
      line-height: em(26, 20);

      @include media-breakpoint-down(sm) {
        font-size: em(11);
        line-height: em(14, 11);
      }
    }

    &--socials {
      font-size: em(20);
      line-height: em(26, 20);

      @include media-breakpoint-down(sm) {
        font-size: em(16);
        line-height: em(21, 16);
      }
    }
  }

  &__icons {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
      gap: em(68);
      margin-left: em(44);
    }

    @include media-breakpoint-down(sm) {
      justify-content: space-between;
      margin-top: em(27);
    }
  }

  &__icon {
    width: em(38);
    height: em(38);
  }

  &__section {
    @include media-breakpoint-up(sm) {
      grid-template-columns:
        map-get($grid-default-columns, 1) map-get($grid-default-columns, g1)
        map-get($grid-default-columns, 3) 1fr map-get($grid-default-columns, 3);

      @include grid-columns($add-base: true);
    }

    &--top {
      padding-bottom: em(68);
      border-bottom: 1px solid $color-gray-1;

      @include media-breakpoint-down(sm) {
        display: grid;
        grid-template-areas:
          'c1 .'
          'c1 .';
        grid-template-rows: 1fr auto;
        grid-template-columns: em(124) 1fr;
        gap: em(20);
        padding-bottom: em(37);
      }
    }

    &--bottom {
      padding-top: em(57);
      padding-bottom: em(62);

      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        gap: em(25);
        padding-top: em(33);
        padding-bottom: em(37);
      }
    }
  }

  &__col-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include media-breakpoint-down(sm) {
      grid-area: c1;
    }
  }

  &__link {
    &--logo {
      @include media-breakpoint-down(sm) {
        margin-bottom: em(80);
      }
    }
  }

  &__logo {
    width: em($logo-width);
    height: em($logo-height);
    object-fit: contain;
    object-position: left center;

    @include media-breakpoint-down(sm) {
      width: em($logo-width-sm);
      height: em($logo-height-sm);
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      max-width: em(190);
    }
  }

  &__phone {
    margin-bottom: em(32);

    @include media-breakpoint-down(sm) {
      margin-bottom: em(10);
    }
  }

  &__socials {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &--grid-half {
    #{$parent}__section {
      @include media-breakpoint-up(sm) {
        grid-template-columns:
          calc(50% - map-get($grid-default-columns, g1))
          map-get($grid-default-columns, g1)
          map-get($grid-default-columns, 3)
          1fr
          map-get($grid-default-columns, 3);
      }
    }
  }
}
</style>
